import { ClientError } from 'graphql-request';

import {
  ErrorMessage,
  IsFetched,
  IsPlaceholderData,
  PathUrl,
  ReadApiError
} from './fields';

export type FetchItemFetched = IsFetched;
export type FetchItemIsPlaceholderData = IsPlaceholderData;

export type FetchItemGqlQuery = string;

export type FetchItemCacheKey = string;
export type FetchItemUrl = PathUrl;

export type FetchItemErrorMessage = ErrorMessage;
export type FetchItemError = Error | ClientError;

export type BffApiFetchItemError = ReadApiError;

export const enum FetchCacheItemSources {
  MNESIA = 'mnesia',
  DB = 'db',
  ES = 'es'
}
