import { gql } from 'graphql-request';

import {
  UserCreatedAt,
  UserFullName,
  UserID,
  UserImageFile,
  UserImageUUID,
  UserLocale,
  UserNanoID,
  UserPermissionsAction,
  UserUUID,
  UserClient,
  UserCurrentTeamID,
  UserCurrentTeamNanoID,
  UserRoleName,
  UserBlocked,
  UserBlockedTermsAcceptedAt
} from '../../main/users/usersTypes';

export interface FetchFinCurrentUserQueryResponse {
  blocked: UserBlocked;
  blockedTermsAcceptedAt: UserBlockedTermsAcceptedAt;
  client: UserClient;
  createdAt: UserCreatedAt;
  currentTeam: {
    id: UserCurrentTeamID;
    nanoId: UserCurrentTeamNanoID;
  };
  frontendActions: { [action: UserPermissionsAction]: true };
  fullName: UserFullName;
  id: UserID;
  image: {
    uuid: UserImageUUID;
    file: UserImageFile;
  };
  locale: UserLocale;
  nanoId: UserNanoID;
  roles: {
    name: UserRoleName;
  }[];
  uuid: UserUUID;
}

export const FETCH_FIN_CURRENT_USER = gql`
  query FetchFinCurrentUser {
    currentUser {
      id
      uuid
      nanoId
      fullName
      locale
      createdAt
      client
      blocked
      blockedTermsAcceptedAt
      image {
        uuid
        file
      }
      currentTeam {
        id
        nanoId
      }
      frontendActions
      roles {
        name
      }
    }
  }
`;
