import React, { useCallback, ForwardedRef, Fragment, ReactNode } from 'react';
import cl from 'classnames';

import { SimpleModalCommonProps } from './../../SimpleModal.types';
import { ClassName } from '../../../../../types';

import { PureButtonHelper } from '../../../../buttons/PureButtonHelper';
import { PureRefButtonHelper } from '../../../../buttons/PureRefButtonHelper';
import { PureRefIconButtonHelper } from '../../../../buttons/PureRefIconButtonHelper';

import { words } from '../../../../../locales/keys';

interface SimpleModalButtonsProps {
  handleSubmit?: () => void;
  hideModal: () => void;
  containerClassName?: ClassName;
  childrenButtons?: ReactNode;
}

const SimpleModalButtons = React.forwardRef<
  HTMLButtonElement,
  SimpleModalButtonsProps & SimpleModalCommonProps
>(
  (
    componentProps: SimpleModalButtonsProps & SimpleModalCommonProps,
    ref: ForwardedRef<HTMLButtonElement>
  ) => {
    const {
      disabled,
      handleSubmit,
      hideModal,
      i18nCancelText = words.cancel,
      i18nSubmitText = words.save,
      i18nCloseText = words.close,
      submitIcon,
      submitClass,
      submitIconClassName,
      isLoading,
      submitDisabled,
      submitButtonColor = 'blue',
      withoutCancelButton = false,
      withCloseButton = false,
      withoutSubmitButton = false,
      onCancel,
      closeOnCancel = true,
      cancelButtonPosition = 'end',
      containerClassName,
      childrenButtons
    } = componentProps;
    const handleCancel = useCallback<() => void>(() => {
      if (closeOnCancel) {
        hideModal();
      }
      onCancel?.();
    }, [closeOnCancel, onCancel, hideModal]);

    return (
      <div className="flex-shrink">
        <div className={containerClassName || 'flex p-4 space-x-2 justify-end'}>
          <div
            className={cancelButtonPosition === 'start' ? 'flex-1' : undefined}
          >
            {withoutCancelButton ? null : (
              <PureButtonHelper
                className="flex-1 sm:flex-initial rounded-md border px-4 py-2 border-transparent text-sm font-medium text-gray-700 dark:text-gray-300 hover:bg-gray-50 dark:hover:bg-gray-800"
                disabled={isLoading || disabled}
                i18nText={i18nCancelText}
                onClick={handleCancel}
              />
            )}
          </div>
          <div className="flex space-x-2">
            {withCloseButton && (
              <PureRefButtonHelper
                className="font-medium rounded-md px-4 py-2 text-sm text-gray-700 dark:text-gray-300 hover:bg-gray-200 dark:hover:bg-gray-700 focus:ring-offset-0"
                disabled={isLoading || disabled}
                i18nText={i18nCloseText}
                onClick={hideModal}
                ref={ref}
              />
            )}

            {childrenButtons}

            {withoutSubmitButton ? null : (
              <Fragment>
                {submitIcon ? (
                  <PureRefIconButtonHelper
                    className={cl(
                      submitClass ||
                        'flex-1 sm:flex-initial rounded-md border px-4 py-2 border-transparent text-sm font-medium',
                      {
                        'bg-red-600 hover:bg-red-700 text-white shadow-sm hover:shadow-md':
                          submitButtonColor === 'red',
                        'bg-blue-600 hover:bg-blue-700 text-white shadow-sm hover:shadow-md':
                          submitButtonColor === 'blue',
                        'bg-green-500 hover:bg-green-600 text-white shadow-sm hover:shadow-md':
                          submitButtonColor === 'green',
                        'text-gray-700 dark:text-gray-300 hover:bg-gray-50 dark:hover:bg-gray-800':
                          submitButtonColor === 'gray'
                      }
                    )}
                    disabled={isLoading || disabled || submitDisabled}
                    i18nText={isLoading ? words.processing : i18nSubmitText}
                    icon={submitIcon}
                    iconClassName={submitIconClassName}
                    ref={ref}
                    onClick={handleSubmit}
                  />
                ) : (
                  <PureRefButtonHelper
                    className={cl(
                      submitClass ||
                        'flex-1 sm:flex-initial rounded-md border px-4 py-2 border-transparent text-sm font-medium',
                      {
                        'text-white bg-red-600 hover:bg-red-700 shadow-sm hover:shadow-md':
                          submitButtonColor === 'red',
                        'text-white bg-blue-600 hover:bg-blue-700 shadow-sm hover:shadow-md':
                          submitButtonColor === 'blue',
                        'text-white bg-green-500 hover:bg-green-600 shadow-sm hover:shadow-md':
                          submitButtonColor === 'green',
                        'text-gray-700 dark:text-gray-300 hover:bg-gray-50 dark:hover:bg-gray-800':
                          submitButtonColor === 'gray'
                      }
                    )}
                    disabled={isLoading || disabled || submitDisabled}
                    i18nText={isLoading ? words.processing : i18nSubmitText}
                    ref={ref}
                    onClick={handleSubmit}
                  />
                )}
              </Fragment>
            )}
          </div>
        </div>
      </div>
    );
  }
);

SimpleModalButtons.displayName = 'SimpleModalButtons';

export default SimpleModalButtons;
