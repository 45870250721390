import {
  BitTaskStatuses,
  FetchTasksFilterFields,
  FetchTasksSortTypes,
  TaskStatuses
} from './tasksTypes';

import { statusesKeys } from '../../locales/keys/models/statuses';

export const INITIAL_TASKS_FILTERS = {};
export const INITIAL_TASKS_PAGE = 1;
export const INITIAL_TASKS_SORT = [FetchTasksSortTypes.CREATED_AT_DESC];
export const INITIAL_TASKS_LIMIT = 50;

export const tasksStatuses = [
  {
    value: TaskStatuses.SETTING_TASK,
    i18nLabel: statusesKeys.types.definition
  },
  {
    value: TaskStatuses.IN_PROGRESS,
    i18nLabel: statusesKeys.types.implementation
  },
  { value: TaskStatuses.QUALITY_CONTROL, i18nLabel: statusesKeys.types.qa },
  {
    value: TaskStatuses.PENDING_MORE_INFO,
    i18nLabel: statusesKeys.types.incompleteSpecification
  },
  {
    value: TaskStatuses.READY_FOR_ACCEPTANCE,
    i18nLabel: statusesKeys.types.acceptance
  },
  { value: TaskStatuses.PAUSED, i18nLabel: statusesKeys.types.paused },
  { value: TaskStatuses.DONE, i18nLabel: statusesKeys.types.performed },
  { value: TaskStatuses.CANCELED, i18nLabel: statusesKeys.types.canceled }
];

export const taskI18nTextStatuses = {
  [TaskStatuses.SETTING_TASK]: statusesKeys.types.definition,
  [BitTaskStatuses.SETTING]: statusesKeys.types.definition,

  [TaskStatuses.IN_PROGRESS]: statusesKeys.types.implementation,
  [BitTaskStatuses.PROGRESS]: statusesKeys.types.implementation,

  [BitTaskStatuses.PENDING_MORE_INFO]:
    statusesKeys.types.incompleteSpecification,
  [TaskStatuses.PENDING_MORE_INFO]: statusesKeys.types.incompleteSpecification,

  [TaskStatuses.PAUSED]: statusesKeys.types.paused,

  [BitTaskStatuses.PENDING_PAYMENTS]: statusesKeys.types.paymentWaiting,

  [TaskStatuses.QUALITY_CONTROL]: statusesKeys.types.qa,

  [BitTaskStatuses.WAITING_FOR_REVIEW]: statusesKeys.types.checking,

  [BitTaskStatuses.READY_FOR_ACCEPTANCE]: statusesKeys.types.acceptance,
  [TaskStatuses.READY_FOR_ACCEPTANCE]: statusesKeys.types.acceptance,

  [BitTaskStatuses.DONE]: statusesKeys.types.performed,
  [TaskStatuses.DONE]: statusesKeys.types.performed,

  [TaskStatuses.CANCELED]: statusesKeys.types.canceled
};

export const enum TaskTabs {
  BRIEF = 'brief',
  MESSAGES = 'messages',
  SMART_CONTRACTS = 'smartContracts',
  ATTACHMENTS = 'attachments',
  RESULTS = 'results',
  TEAM = 'team',
  NOTIFICATIONS = 'notifications',
  TRANSACTIONS = 'transactions',
  PAYMENTS = 'payments',
  ACTIVITY = 'activity',
  JOURNAL_RECORDS = 'journal-records',
  WHITEBOARD = 'whiteboard',
  WHITEBOARD_BACKUPS = 'whiteboard-backups'
}

export const enum TasksPermissions {
  READ_CREATE_TASK_ITEMS_DEFAULT_SPLIT_PARTS = 'read_create_task_items_default_split_parts',
  READ_ABORT_TASK_BUTTON = 'read_abort_task_button',
  READ_TASKS_TABLE_SC_TAT_FIELD = 'read_tasks_table_sc_tat_field',
  READ_USERS_GROUP_TASKS_TABLE_SELECTED_FIELD = 'read_users_group_tasks_table_selected_field',
  READ_USERS_GROUP_TASKS_TABLE_COMPANY_OWNER_AVATAR_FIELD = 'read_users_group_tasks_table_company_owner_avatar_field',
  READ_USERS_GROUP_TASKS_TABLE_COMPANY_OWNER_NAME_FIELD = 'read_users_group_tasks_table_company_owner_name_field',
  READ_TASKS_TABLE_TAGS_FIELD = 'read_tasks_table_tags_field',
  READ_TASKS_RESULTS_NAME_FILTER = 'read_tasks_results_name_filter',
  READ_TASKS_RESULTS_FILTERS_BUTTON = 'read_tasks_results_filters_button',
  READ_TASKS_TABLE_DEBT_FIELD = 'read_tasks_table_debt_field',
  READ_TASKS_COMPACT_FINANCE_PAGE = 'read_tasks_compact_finance_page',
  READ_TASKS_TABLE_NOTES_FIELD = 'read_tasks_table_notes_field',
  EDIT_TASKS_TABLE_NOTES_FIELD = 'edit_tasks_table_notes_field',
  READ_TASKS_COMPACT_NOTES_PAGE = 'read_tasks_compact_notes_page',
  READ_TASKS_SETTING_TASK_TAB_BADGE = 'read_tasks_setting_task_tab_badge',
  READ_TASKS_IN_PROGRESS_TAB_BADGE = 'read_tasks_in_progress_tab_badge',
  READ_TASKS_QUALITY_CONTROL_TAB_BADGE = 'read_tasks_quality_control_tab_badge',
  READ_TASKS_READY_FOR_ACCEPTANCE_TAB_BADGE = 'read_tasks_ready_for_acceptance_tab_badge',
  READ_TASKS_DONE_TAB_BADGE = 'read_tasks_done_tab_badge',
  READ_TASKS_CANCELED_TAB_BADGE = 'read_tasks_canceled_tab_badge',
  READ_TASKS_PAUSED_TAB_BADGE = 'read_tasks_paused_tab_badge',
  READ_TASKS_PENDING_MORE_INFO_TAB_BADGE = 'read_tasks_pending_more_info_tab_badge',
  READ_TASKS_ATTACHMENTS_DELETE_SOURCE_FILE_BUTTON = 'read_tasks_attachments_delete_source_file_button',
  READ_TASKS_ATTACHMENTS_DELETE_SELECTED_LIFESTYLE_BUTTON = 'read_tasks_attachments_delete_selected_lifestyle_button',
  READ_TASKS_ATTACHMENTS_DELETE_SELECTED_PRODUCT_BUTTON = 'read_tasks_attachments_delete_selected_product_button',
  READ_TASKS_ATTACHMENTS_DELETE_SELECTED_MATERIAL_BUTTON = 'read_tasks_attachments_delete_selected_material_button',
  READ_TASKS_ATTACHMENTS_DELETE_FILE_BUTTON = 'read_tasks_attachments_delete_file_button',
  READ_TASKS_ATTACHMENTS_DELETE_IMAGE_BUTTON = 'read_tasks_attachments_delete_image_button',
  READ_TASKS_ATTACHMENTS_REMOVE_FILE_BUTTON = 'read_tasks_attachments_remove_file_button',
  READ_TASKS_ATTACHMENTS_REMOVE_IMAGE_BUTTON = 'read_tasks_attachments_remove_image_button',
  READ_TASKS_ATTACHMENTS_VIEW_3D_MODEL_BUTTON = 'read_tasks_attachments_view_3d_model_button',
  READ_TASKS_ATTACHMENTS_SUBMIT_SELECTED_MATERIALS_MODAL_BUTTON = 'read_tasks_attachments_submit_selected_materials_modal_button',
  READ_TASKS_RESULTS_VIEW_3D_MODEL_BUTTON = 'read_tasks_results_view_3d_model_button',
  READ_TASKS_INDEX_PAGE = 'read_tasks_index_page',
  READ_TASK_INVITED_MEMBERS_TABLE_DELETE_BUTTON = 'read_task_invited_members_table_delete_button',
  READ_TASK_BRIEF_PAGE = 'read_task_brief_page',
  READ_TASK_MESSAGES_PAGE = 'read_task_messages_page',
  READ_TASK_SMART_CONTRACTS_PAGE = 'read_task_smart_contracts_page',
  READ_TASK_ATTACHMENTS_PAGE = 'read_task_attachments_page',
  READ_TASK_FILES_PAGE = 'read_task_files_page',
  READ_TASK_IMAGES_PAGE = 'read_task_images_page',
  READ_TASK_SOURCE_FILES_PAGE = 'read_task_source_files_page',
  READ_TASK_PRODUCTS_PAGE = 'read_task_products_page',
  READ_TASK_LIFESTYLES_PAGE = 'read_task_lifestyles_page',
  READ_TASK_MATERIALS_PAGE = 'read_task_materials_page',
  READ_TASK_RESULTS_PAGE = 'read_task_results_page',
  READ_TASK_TEAM_PAGE = 'read_task_team_page',
  READ_TASK_NOTIFICATIONS_PAGE = 'read_task_notifications_page',
  READ_TASK_TRANSACTIONS_PAGE = 'read_task_transactions_page',
  READ_TASK_PAYMENTS_PAGE = 'read_task_payments_page',
  READ_TASK_PAYMENTS_WITHOUT_DONE_FILTER = 'read_task_payments_without_done_filter',
  READ_TASK_PAYMENTS_STATUS_FILTER = 'read_task_payments_status_filter',
  READ_TASK_PAYMENTS_SELECTED_ITEMS_TOTAL = 'read_task_payments_selected_items_total',
  READ_TASK_PAYMENTS_ADD_ITEM_BUTTON = 'read_task_payments_add_item_button',
  READ_TASK_PAYMENTS_PARENT_INVOICE_ID_FILTER = 'read_task_payments_parent_invoice_id_filter',
  READ_TASK_ACCEPT_PAGE = 'read_task_accept_page',
  READ_TASK_ACTIVITY_PAGE = 'read_task_activity_page',
  READ_TASK_WHITEBOARD_PAGE = 'read_task_whiteboard_page',
  READ_TASK_WHITEBOARD_BACKUPS_PAGE = 'read_task_whiteboard_backups_page',
  READ_TASK_WHITEBOARD_BACKUP_PAGE = 'read_task_whiteboard_backup_page',
  READ_TASKS_RESULTS_PAGE = 'read_tasks_results_page',
  READ_TASK_NDA = 'read_task_nda',
  READ_TASKS_RESULTS_CHECKBOX = 'read_tasks_results_checkbox',
  READ_TASK_RESULTS_PAGE_CHECKBOXES = 'read_task_results_page_checkboxes',
  READ_TASK_SEND_INVOICE_BUTTON = 'read_task_send_invoice_button',
  READ_TASK_SUBMIT_RESULTS_BUTTON = 'read_task_submit_results_button',
  READ_TASK_SUBMIT_RESULTS_ACTION_BUTTON = 'read_task_submit_results_action_button',
  READ_ACCEPT_TASK_BUTTON = 'read_accept_task_button',
  READ_CANCEL_TASK_BUTTON = 'read_cancel_task_button',
  READ_CLOSE_TASK_BUTTON = 'read_close_task_button',
  READ_PAUSE_TASK_BUTTON = 'read_pause_task_button',
  READ_CHECK_IN_TASK_BUTTON = 'read_check_in_task_button',
  READ_USER_CHECK_IN_TASK_BUTTON = 'read_user_check_in_task_button',
  READ_SELF_MANY_CHECK_IN_TASK_BUTTON = 'read_self_many_check_in_task_button',
  READ_USER_MANY_CHECK_IN_TASK_BUTTON = 'read_user_many_check_in_task_button',
  READ_START_TASK_BUTTON = 'read_start_task_button',
  READ_INCOMPLETE_SPECIFICATION_TASK_BUTTON = 'read_incomplete_specification_task_button',
  READ_REVISION_TASK_BUTTON = 'read_revision_task_button',
  READ_REVISION_QA_TASK_BUTTON = 'read_revision_qa_task_button',
  READ_RESTORE_TASK_BUTTON = 'read_restore_task_button',
  READ_RESTART_TASK_BUTTON = 'read_restart_task_button',
  READ_ACCEPT_RESULTS_IN_TASK_BUTTON = 'read_accept_results_in_task_button',
  READ_ACCEPT_RESULTS_IN_TASK_ASYNC_BUTTON = 'read_accept_results_in_task_async_button',
  READ_DOWNLOAD_TASK_RESULTS_BUTTON = 'read_download_task_results_button',
  READ_APPROVE_RESULTS_IN_TASK_BUTTON = 'read_approve_results_in_task_button',
  READ_APPROVE_RESULTS_IN_TASK_FINAL_RESULT = 'read_approve_results_in_task_final_result',
  READ_TASK_SUBMIT_SOURCE_FILES_BUTTON = 'read_task_submit_source_files_button',
  READ_TASK_STATUS_BLOCK_SUBMIT_SOURCE_FILES_BUTTON = 'read_task_status_block_submit_source_files_button',
  READ_TASK_LEAVE_BUTTON = 'read_task_leave_button',
  READ_TASKS_SAVED_FILTERS_MENU = 'read_tasks_saved_filters_menu',
  CHANGE_TASK_SUPPORT_MARK = 'change_task_support_mark',
  CHANGE_TASK_MARK = 'change_task_mark',
  CHANGE_TASK_RED = 'change_task_red',
  CHANGE_TASK_FAVORITE = 'change_task_favorite',
  CHANGE_TASK_VISIBLE_FOR_CLIENT = 'change_task_visible_for_client',
  CHANGE_FILE_ATTACHMENT_VISIBLE_FOR_CLIENTS = 'change_file_attachment_visible_for_clients',
  READ_TASK_TRACK_TIME_BUTTON = 'read_task_track_time_button',
  READ_TASK_TAGS = 'read_task_tags',
  READ_TASK_JOURNAL_RECORDS_PAGE = 'read_task_journal_records_page',
  READ_TASK_JOURNAL_RECORDS_TAB = 'read_task_journal_records_tab',
  READ_TASK_WHITEBOARD_TAB = 'read_task_whiteboard_tab',
  READ_TASK_WHITEBOARD_BACKUPS_TAB = 'read_task_whiteboard_backups_tab',
  READ_TASKS_PRICE_FILTER = 'read_tasks_price_filter',
  READ_TASKS_BURNING_FILTER_MENU = 'read_tasks_burning_filter_menu',
  READ_TASKS_INVITATIONS_FILTER_MENU = 'read_tasks_invitations_filter_menu',
  READ_TASKS_FAVORITE_FILTER_MENU = 'read_tasks_favorite_filter_menu',
  READ_TASKS_MY_TASKS_FILTER_MENU = 'read_tasks_my_tasks_filter_menu',
  READ_TASKS_WITHOUT_SOURCE_FILES_FILTER_MENU = 'read_tasks_without_source_files_filter_menu',
  READ_TASKS_CURRENT_TASKS_FILTER_MENU = 'read_tasks_current_tasks_filter_menu',
  READ_TASKS_RESULTS_MENU = 'read_tasks_results_menu',
  READ_TASKS_TABLE_LAST_ACTIVITY_FIELD = 'read_tasks_table_last_activity_field',
  READ_TASKS_TABLE_UUID_FIELD = 'read_tasks_table_uuid_field',
  READ_TASKS_TABLE_FAVORITE_FIELD = 'read_tasks_table_favorite_field',
  READ_TASKS_TABLE_CHECKBOXES = 'read_tasks_table_checkboxes',
  READ_TASKS_TABLE_BURNING_FIELD = 'read_tasks_table_burning_field',
  READ_TASKS_TABLE_USER_FIELD = 'read_tasks_table_user_field',
  READ_TASKS_TABLE_OWNER_FIELD = 'read_tasks_table_owner_field',
  READ_TASKS_TABLE_COMPANY_AVATAR_FIELD = 'read_tasks_table_company_avatar_field',
  READ_TASKS_TABLE_TEAM_FIELD = 'read_tasks_table_team_field',
  READ_TASKS_TABLE_IMPLEMENTATION_DATE_FIELD = 'read_tasks_table_implementation_date_field',
  READ_TASKS_TABLE_STATUS_FIELD = 'read_tasks_table_status_field',
  READ_TASKS_TABLE_ITEMS_FIELD = 'read_tasks_table_items_field',
  READ_TASKS_TABLE_PRICE_FIELD = 'read_tasks_table_price_field',
  READ_TASKS_TABLE_TOTAL_PRICE_FIELD = 'read_tasks_table_total_price_field',
  READ_TASKS_TABLE_SENT_REGULAR_FIELD = 'read_tasks_table_sent_regular_field',
  READ_TASKS_TABLE_OPEN_REGULAR_FIELD = 'read_tasks_table_open_regular_field',
  READ_TASKS_TABLE_PAID_REGULAR_FIELD = 'read_tasks_table_paid_regular_field',
  READ_TASKS_TABLE_AMOUNT_FIELD = 'read_tasks_table_amount_field',
  READ_TASKS_TABLE_CLIENT_PREPAYMENT_FIELD = 'read_tasks_table_client_prepayment_field',
  READ_TASKS_TABLE_ID_FIELD = 'read_tasks_table_id_field',
  READ_TASKS_TABLE_CREATED_AT_FIELD = 'read_tasks_table_created_at_field',
  READ_TASKS_TABLE_STATUS_UPDATED_AT_FIELD = 'read_tasks_table_status_updated_at_field',
  READ_TASKS_TABLE_START_DATE_FIELD = 'read_tasks_table_start_date_field',
  READ_TASKS_TABLE_NAME_FIELD = 'read_tasks_table_name_field',
  READ_TASKS_TABLE_DESCRIPTION_FIELD = 'read_tasks_table_description_field',
  READ_TASKS_TABLE_LAST_MESSAGE_FIELD = 'read_tasks_table_last_message_field',
  READ_TASKS_TABLE_PROJECT_NAME_FIELD = 'read_tasks_table_project_name_field',
  READ_TASKS_TABLE_NOTIFICATION_DISABLED_FIELD = 'read_tasks_table_notifications_disabled_field',
  READ_TASKS_TABLE_PROJECT_LINK_FIELD = 'read_tasks_table_project_link_field',
  READ_TASKS_TABLE_SOURCE_FILES_BUTTON = 'read_tasks_table_source_files_button',
  READ_FILE_ATTACHMENT_VISIBLE_FOR_CLIENTS = 'read_file_attachment_visible_for_clients',
  READ_TASK_FILE_ATTACHMENT_TYPE_BADGE = 'read_file_attachment_assigned_type_badge',
  READ_TASK_FILE_ATTACHMENT_ASSIGN_TYPE_BUTTON = 'read_task_file_attachment_assign_type_button',
  READ_TASK_TRANSACTIONS_TABLE_ACCOUNT_BALANCE_FIELD = 'read_task_transactions_table_account_balance_field',
  READ_APPROVE_TASK_RESULTS_MODAL_BUTTON = 'read_approve_task_results_modal_button',
  READ_FIN_APPROVE_TASK_RESULTS_MODAL_BUTTON = 'read_fin_approve_task_results_modal_button',
  READ_ACCEPT_TASK_RESULTS_MODAL_BUTTON = 'read_accept_task_results_modal_button',
  READ_FIN_ACCEPT_TASK_RESULTS_MODAL_BUTTON = 'read_fin_accept_task_results_modal_button',
  CHANGE_TASK_DESCRIPTION = 'change_task_description',
  CHANGE_TASK_REVISION_IMPLEMENTATION_DATE_FILED = 'change_task_revision_implementation_date_field',
  CHANGE_TASK_AMOUNT = 'change_task_amount',
  CHANGE_TASK_IMPLEMENTATION_DATE = 'change_task_implementation_date',
  CHANGE_TASK_IMPLEMENTATION_TIME = 'change_task_implementation_time',
  CHANGE_TASK_WIDTH = 'change_task_width',
  CHANGE_TASK_HEIGHT = 'change_task_height',
  CHANGE_TASK_DPI = 'change_task_dpi',
  CHANGE_TASK_FILE_EXTENSION = 'change_task_file_extension',
  CHANGE_TASK_FILE_ATTACHMENT_IDS = 'change_task_file_attachment_ids',
  READ_TASK_ID = 'read_task_id',
  READ_TASK_UUID = 'read_task_uuid',
  READ_TASK_NANO_ID = 'read_task_nano_id',
  READ_TASK_AMOUNT = 'read_task_amount',
  CHANGE_TASK_PREPAYMENT = 'change_task_prepayment',
  READ_TASK_PREPAYMENT = 'read_task_prepayment',
  READ_TASK_DEBT = 'read_task_debt',
  READ_TASK_TASK_ACCOUNT = 'read_task_task_account',
  READ_TASK_TAG_IDS = 'read_task_tag_ids',
  CHANGE_TASK_TAG_IDS = 'change_task_tag_ids',
  READ_TASK_STATUS = 'read_task_status',
  READ_TASK_CLIENT_NEW_LABEL = 'read_task_client_new_label',
  READ_TASK_CLIENT_MY_LABEL = 'read_task_client_my_label',
  READ_TASK_PROCESSING_JOBS = 'read_task_processing_jobs',
  READ_TASK_VERSION = 'read_task_version',
  READ_TASK_STATE = 'read_task_state',
  READ_TASK_VERSION_NUMBER = 'read_task_version_number',
  CHANGE_TASK_VERSION_NUMBER = 'change_task_version_number',
  CHANGE_TASK_STATE = 'change_task_state',
  CHANGE_TASK_STATUS = 'change_task_status',
  READ_NEW_TASK_BUTTON = 'read_new_task_button',
  READ_NEW_TASK_LINK_BUTTON = 'read_new_task_link_button',
  READ_NEW_TASK_DROPDOWN = 'read_new_task_dropdown',
  READ_NEW_MULTIPLE_TASKS_BUTTON = 'read_new_multiple_tasks_button',
  READ_NEW_MULTIPLE_TASKS_LINK_BUTTON = 'read_new_multiple_tasks_link_button',
  READ_IMPORT_TASKS_TO_PROJECT_BUTTON = 'read_import_tasks_to_project_button',
  READ_TASK_FAVORITE_BUTTON = 'read_task_favorite_button',
  READ_TASK_VISIBLE_FOR_CLIENT = 'read_task_visible_for_client',
  READ_TASK_TOGGLE_NOTIFICATIONS_BUTTON = 'read_task_toggle_notifications_button',
  READ_TASK_MEMBER_TRACKED_TIME = 'read_task_member_tracked_time',
  READ_TASK_INVITE_USER_BUTTON = 'read_task_invite_user_button',
  READ_TASK_ADD_ITEM_BUTTON = 'read_task_add_item_button',
  READ_TASK_ADD_ITEM_SEND_INVOICE = 'read_task_add_item_send_invoice',
  READ_TASK_TEAM_MEMBER_MENU_DROPDOWN = 'read_task_team_member_menu_dropdown',
  READ_TASK_TEAM_MEMBER_ASSIGN_AS_CREATOR_BUTTON = 'read_task_team_member_assign_as_creator_button',
  READ_TASK_TEAM_MEMBER_ASSIGN_AS_OWNER_BUTTON = 'read_task_team_member_assign_as_owner_button',
  READ_TASK_CHANGE_OWNER_BUTTON = 'read_task_change_owner_button',
  READ_TASK_UPDATE_NAME_BUTTON = 'read_task_update_name_button',
  READ_TASK_UPDATE_DESCRIPTION_BUTTON = 'read_task_update_description_button',
  CREATE_TASK_BILL = 'create_task_bill',
  READ_TASK_ATTACHMENTS_MATERIALS_FOLDER = 'read_task_attachments_materials_folder',
  READ_TASK_ATTACHMENTS_SOURCE_FILES_FOLDER = 'read_task_attachments_source_files_folder',
  READ_TASK_SUPPORT_MARK = 'read_task_support_mark',
  READ_TASK_MARK = 'read_task_mark',
  READ_TASK_CLIENT_MENU_DROPDOWN = 'read_task_client_menu_dropdown',
  READ_TASK_IMPLEMENTATION_DATE = 'read_task_implementation_date',
  READ_TASK_CLIENT_LOCAL_TIME = 'read_task_client_local_time',
  READ_TASK_ARCHIVIZER_TEAM_LOCAL_TIME = 'read_task_archivizer_team_local_time',
  READ_TASK_EXPECTED_TIME = 'read_task_expected_time',
  CREATE_TASK_DESCRIPTION = 'create_task_description',
  CREATE_TASK_AMOUNT = 'create_task_amount',
  CREATE_TASK_IMPLEMENTATION_DATE = 'create_task_implementation_date',
  CREATE_TASK_IMPLEMENTATION_TIME = 'create_task_implementation_time',
  CREATE_TASK_WIDTH = 'create_task_width',
  CREATE_TASK_HEIGHT = 'create_task_height',
  CREATE_TASK_DPI = 'create_task_dpi',
  CREATE_TASK_FILE_EXTENSION = 'create_task_file_extension',
  CREATE_TASK_FILE_ATTACHMENT_IDS = 'create_task_file_attachment_ids',
  CREATE_TASK_TAG_IDS = 'create_task_tag_ids',
  READ_TASK_REVISION_IMPLEMENTATION_DATE_FILED = 'read_task_revision_implementation_date_field',
  READ_TASK_TRANSACTIONS_ID = 'read_task_transactions_id',
  READ_TASK_TRANSACTIONS_CREATED_AT = 'read_task_transactions_created_at',
  READ_TASK_TRANSACTIONS_AMOUNT = 'read_task_transactions_amount',
  READ_TASK_TRANSACTIONS_TOTAL_BALANCE = 'read_task_transactions_total_balance',
  READ_TASK_TRANSACTIONS_AUTHOR = 'read_task_transactions_author',
  READ_TASK_TRANSACTIONS_KIND = 'read_task_transactions_kind',
  READ_TASK_TRANSACTIONS_BALANCE_TYPE = 'read_task_transactions_balance_type',
  READ_TASK_TRANSACTIONS_BALANCE = 'read_task_transactions_balance',
  READ_TASK_TRANSACTIONS_PAYMENT_TYPE = 'read_task_transactions_payment_type',
  READ_TASK_TRANSACTIONS_TRANSACTION_TAGS = 'read_task_transactions_transaction_tags',
  READ_TASK_TRANSACTIONS_DESCRIPTION = 'read_task_transactions_description',
  READ_INVOICE_FORM_DESCRIPTION_FILED = 'read_invoice_form_description_field',
  READ_TASK_MESSAGES_TAB = 'read_task_messages_tab',
  READ_TASK_BRIEF_TAB = 'read_task_brief_tab',
  READ_TASK_SMART_CONTRACTS_TAB = 'read_task_smart_contracts_tab',
  READ_TASK_ATTACHMENTS_TAB = 'read_task_attachments_tab',
  READ_TASK_RESULTS_TAB = 'read_task_results_tab',
  READ_TASK_TEAM_TAB = 'read_task_team_tab',
  READ_TASK_NOTIFICATIONS_TAB = 'read_task_notifications_tab',
  READ_TASK_TRANSACTIONS_TAB = 'read_task_transactions_tab',
  READ_TASK_ACTIVITY_TAB = 'read_task_activity_tab',
  READ_TASK_PAYMENTS_TAB = 'read_task_payments_tab',
  READ_TASKS_TAG_IDS_FILTER = 'read_tasks_tag_ids_filter',
  READ_TASKS_START_DATE_FILTER = 'read_tasks_start_date_filter',
  READ_TASKS_SOURCE_FILES_FILTER = 'read_tasks_source_files_filter',
  READ_TASK_CREATE_BILL_BUTTON = 'read_task_create_bill_button',
  READ_TASK_START_NEW_VERSION_BUTTON = 'read_task_start_new_version_button',
  READ_DOWNLOAD_QA_TASK_RESULTS_BUTTON = 'read_download_qa_task_results_button',
  CHANGE_TASK_ATTACHMENTS_CHECKED = 'change_task_attachments_checked',
  READ_TASK_TRANSACTIONS_DIRECTION = 'read_task_transactions_direction',
  READ_TASKS_BURNING_FILTER = 'read_tasks_burning_filter',
  CHANGE_TASK_IMAGES_CHECKED = 'change_task_images_checked',
  CHANGE_TASK_FILES_CHECKED = 'change_task_files_checked',
  CHANGE_TASK_SOURCE_FILES_CHECKED = 'change_task_source_files_checked',
  CHANGE_TASK_MATERIALS_CHECKED = 'change_task_materials_checked',
  CHANGE_TASK_PRODUCTS_CHECKED = 'change_task_products_checked',
  CHANGE_TASK_LIFESTYLES_CHECKED = 'change_task_lifestyles_checked',
  READ_DEFAULT_TASK_PRICE = 'read_default_task_price',
  READ_TASK_SEND_RESULTS_BUTTON = 'read_task_send_results_button',
  READ_TASK_DOWNLOAD_TASK_RESULTS_BUTTON = 'read_task_download_task_results_button',
  READ_TASK_UPLOAD_SOURCE_FILES_BUTTON = 'read_task_upload_source_files_button',
  READ_TASK_DOWNLOAD_SOURCE_FILES_BUTTON = 'read_task_download_source_files_button',
  READ_TASKS_SEARCH_FILTERS_POPOVER = 'read_tasks_search_filters_popover',
  READ_TASKS_RESULTS_SEARCH_FILTERS_POPOVER = 'read_tasks_results_search_filters_popover',
  READ_TASK_RESULT_EDIT_BUTTON = 'read_task_result_edit_button',
  READ_TASK_BRIEF_REMOVE_SCOPE_OF_WORK_MESSAGE_BUTTON = 'read_task_brief_remove_scope_of_work_message_button',
  READ_TASK_BRIEF_REMOVE_STARRED_MESSAGE_BUTTON = 'read_task_brief_remove_starred_message_button',
  READ_TASK_BRIEF_ATTACHMENTS_BLOCK = 'read_task_brief_attachments_block',
  READ_TASK_BRIEF_PINNED_MESSAGES_BLOCK = 'read_task_brief_pinned_messages_block',
  READ_TASK_BRIEF_FLAGGED_MESSAGES_BLOCK = 'read_task_brief_flagged_messages_block',
  READ_TASK_BRIEF_STARRED_MESSAGES_BLOCK = 'read_task_brief_starred_messages_block',
  READ_TASK_TODO_LIST = 'read_task_todo_list',
  READ_TASK_TODO_LIST_ADD_BUTTON = 'read_task_todo_list_add_button',
  CHANGE_TASK_TODO_LIST = 'change_task_todo_list',
  READ_TASK_SIDEBAR_PAYMENTS_BLOCK = 'read_task_sidebar_payments_block',
  READ_TASK_SIDEBAR_STATUS_BLOCK = 'read_task_sidebar_status_block',
  READ_TASK_SIDEBAR_FIN_STATUS_BLOCK = 'read_task_sidebar_fin_status_block',
  READ_TASK_SIDEBAR_NEW_FIN_STATUS_BLOCK = 'read_task_sidebar_new_fin_status_block',
  READ_TASK_SIDEBAR_TASK_SMART_CONTRACT_TEAM_BLOCK = 'read_task_sidebar_task_smart_contract_team_block',
  READ_TASK_SIDEBAR_TASK_SMART_CONTRACT_PAYMENTS_BLOCK = 'read_task_sidebar_task_smart_contract_payments_block',
  READ_TASK_SIDEBAR_TASK_SMART_CONTRACT_PAYMENTS_BLOCK_WITH_REGULAR_NET_INVOICES = 'read_task_sidebar_task_smart_contract_payments_block_with_regular_net_invoices',
  READ_TASK_SIDEBAR_TASK_TEAM_BLOCK = 'read_task_sidebar_task_team_block',
  READ_TASK_SIDEBAR_TASK_FILES_BLOCK = 'read_task_sidebar_task_files_block',
  READ_TASK_SIDEBAR_TASK_MEDIA_BLOCK = 'read_task_sidebar_task_media_block',
  READ_TASK_SIDEBAR_FILE_ATTACHMENT_EDIT_BUTTON = 'read_task_sidebar_file_attachment_edit_button',
  READ_TASK_SIDEBAR_COEFFICIENT_BLOCK = 'read_task_sidebar_coefficient_block',
  READ_TASK_SIDEBAR_NOTES_BLOCK = 'read_task_sidebar_notes_block',
  READ_TASK_SIDEBAR_VERSION_BLOCK = 'read_task_sidebar_version_block',
  READ_TASK_ACTIVE_TASK_SMART_CONTRACT_PAYMENTS_BLOCK = 'read_task_active_task_smart_contract_payments_block',
  READ_TASK_ATTACHMENTS_FILE_ATTACHMENT_EDIT_BUTTON = 'read_task_attachments_file_attachment_edit_button',
  READ_TASKS_BATCH_TRACK_TIME_BUTTON = 'read_tasks_batch_track_time_button',
  READ_TASKS_BATCH_UPDATE_TASKS_TAG_IDS_BUTTON = 'read_tasks_batch_update_tasks_tag_ids_button',
  READ_TASKS_BATCH_INVITE_USERS_TO_TASKS_BUTTON = 'read_tasks_batch_invite_users_to_tasks_button',
  READ_TASKS_BATCH_CREATE_TASKS_BILLS_BUTTON = 'read_tasks_batch_create_tasks_bills_button',
  READ_TASKS_BATCH_ACCEPT_TASKS_RESULTS_BUTTON = 'read_tasks_batch_accept_tasks_results_button',
  READ_FIN_TASKS_BATCH_ACCEPT_TASKS_RESULTS_BUTTON = 'read_fin_tasks_batch_accept_tasks_results_button',
  READ_TASKS_RESULTS_BATCH_ACCEPT_RESULTS_BUTTON = 'read_tasks_results_batch_accept_results_button',
  READ_TASKS_RESULTS_BATCH_DOWNLOAD_RESULTS_BUTTON = 'read_tasks_results_batch_download_results_button',
  READ_TASK_RESULTS_PAGE_BATCH_DOWNLOAD_RESULTS_BUTTON = 'read_task_results_page_batch_download_results_button',
  READ_FIN_TASKS_RESULTS_BATCH_ACCEPT_RESULTS_BUTTON = 'read_fin_tasks_results_batch_accept_results_button',
  READ_TASKS_BATCH_UPDATE_TASKS_STATUS_BUTTON = 'read_tasks_batch_update_tasks_status_button',
  READ_INVOICE_FORM_TRANSACTION_TAGS_FIELD = 'read_invoice_form_transaction_tags_field',
  READ_TASKS_BATCH_COPY_TASKS_TO_PROJECT_BUTTON = 'read_tasks_batch_copy_tasks_to_project_button',
  READ_TASKS_BATCH_IMPORT_ALLOCATE_TASKS_FUNDS_BUTTON = 'read_tasks_batch_import_allocate_tasks_funds_button',
  READ_TASKS_BATCH_UPDATE_IMPLEMENTATION_DATES_BUTTON = 'read_tasks_batch_update_implementation_dates_button',
  READ_TASKS_BATCH_UPDATE_AMOUNTS_BUTTON = 'read_tasks_batch_update_amounts_button',
  READ_TASKS_BATCH_ALLOCATE_FUNDS_BUTTON = 'read_tasks_batch_allocate_funds_button',
  READ_TASK_CREATE_MESSAGE_FORM_FULLSCREEN_DROPZONE = 'read_task_create_message_form_fullscreen_dropzone',
  READ_TASK_CREATE_MESSAGE_FORM_HIDDEN_TASK_ALERT = 'read_task_create_message_form_hidden_task_alert',
  READ_TASK_CREATE_MESSAGE_FORM_PASTE_FILES = 'read_task_create_message_form_paste_files',
  READ_TASK_SUBMIT_FILE_ATTACHMENTS_MODAL_BUTTON_FULLSCREEN_DROPZONE = 'read_task_submit_file_attachments_modal_button_fullscreen_dropzone',
  READ_TASKS_INVITATIONS_FILTER_MENU_COUNT = 'read_tasks_invitations_filter_menu_count',
  READ_TASKS_BURNING_FILTER_MENU_COUNT = 'read_tasks_burning_filter_menu_count',
  READ_TASKS_RESULTS_MENU_QA_COUNT = 'read_tasks_results_menu_qa_count',
  READ_TASKS_RESULTS_MENU_RFA_COUNT = 'read_tasks_results_menu_rfa_count',
  READ_TASKS_MY_TASKS_FILTER_MENU_COUNT = 'read_tasks_my_tasks_filter_menu_count',
  READ_TASKS_WITHOUT_SOURCE_FILES_FILTER_MENU_COUNT = 'read_tasks_without_source_files_filter_menu_count',
  READ_TASK_INVITED_MEMBERS_TABLE = 'read_task_invited_members_table',
  READ_TASK_TEAM_MEMBERS_SELF_USERS = 'read_task_team_members_self_users',
  READ_TASK_TEAM_MEMBERS_TIME_TRACKED_FIELD = 'read_task_team_members_time_tracked_field',
  READ_TASK_NOTIFICATIONS_TABLE = 'read_task_notifications_table',
  READ_TASK_SMART_CONTRACT_START_TASK_BUTTON = 'read_task_smart_contract_start_task_button',
  READ_TASK_SMART_CONTRACT_PARTICIPANTS_BLOCK = 'read_task_smart_contract_participants_block',
  READ_TASK_SMART_CONTRACT_NEW_PARTICIPANTS_BLOCK = 'read_task_smart_contract_new_participants_block',
  READ_TASK_SMART_CONTRACT_ACTIONS_BLOCK = 'read_task_smart_contract_actions_block',
  READ_TASK_SMART_CONTRACT_PARTICIPANTS_TABLE_ID_FIELD = 'read_task_smart_contract_participants_table_id_field',
  READ_TASK_SMART_CONTRACT_PARTICIPANTS_TABLE_USER_ID_FIELD = 'read_task_smart_contract_participants_table_user_id_field',
  READ_TASK_SMART_CONTRACT_PARTICIPANTS_TABLE_USER_FIELD = 'read_task_smart_contract_participants_table_user_field',
  READ_TASK_SMART_CONTRACT_PARTICIPANTS_TABLE_ROLE_FIELD = 'read_task_smart_contract_participants_table_role_field',
  READ_TASK_SMART_CONTRACT_PARTICIPANTS_TABLE_SHARE_FIELD = 'read_task_smart_contract_participants_table_share_field',
  READ_TASK_SMART_CONTRACT_PARTICIPANTS_TABLE_AMOUNT_FIELD = 'read_task_smart_contract_participants_table_amount_field',
  READ_TASK_SMART_CONTRACT_PARTICIPANTS_TABLE_PAID_FIELD = 'read_task_smart_contract_participants_table_paid_field',
  READ_TASK_SMART_CONTRACT_PARTICIPANTS_TABLE_DROPDOWN_FIELD = 'read_task_smart_contract_participants_table_dropdown_field',
  READ_TASK_SMART_CONTRACT_PARTICIPANTS_TABLE_INVITED_MEMBERS = 'read_task_smart_contract_participants_table_invited_members',
  READ_SELF_TASK_SMART_CONTRACT_PARTICIPANT_CHANGE_SHARE_BUTTON = 'read_self_task_smart_contract_participant_change_share_button',
  READ_SELF_TASK_SMART_CONTRACT_REMOVE_PARTICIPANT_BUTTON = 'read_self_task_smart_contract_remove_participant_button',
  READ_SELF_TASK_SMART_CONTRACT_PAY_FROM_AV_BUTTON = 'read_self_task_smart_contract_pay_from_av_button',
  READ_SELF_TASK_SMART_CONTRACT_PAY_FROM_PFR_BUTTON = 'read_self_task_smart_contract_pay_from_pfr_button',
  READ_SELF_TASK_SMART_CONTRACT_PAY_FROM_PFBD_BUTTON = 'read_self_task_smart_contract_pay_from_pfbd_button',
  READ_USER_TASK_SMART_CONTRACT_PARTICIPANT_CHANGE_SHARE_BUTTON = 'read_user_task_smart_contract_participant_change_share_button',
  READ_USER_TASK_SMART_CONTRACT_REMOVE_PARTICIPANT_BUTTON = 'read_user_task_smart_contract_remove_participant_button',
  READ_TASK_SMART_CONTRACT_DELETE_TASK_MEMBER_INVITE_BUTTON = 'read_task_smart_contract_delete_task_member_invite_button',
  READ_USER_TASK_SMART_CONTRACT_PAY_FROM_AV_BUTTON = 'read_user_task_smart_contract_pay_from_av_button',
  READ_USER_TASK_SMART_CONTRACT_PAY_FROM_PFR_BUTTON = 'read_user_task_smart_contract_pay_from_pfr_button',
  READ_USER_TASK_SMART_CONTRACT_PAY_FROM_PFBD_BUTTON = 'read_user_task_smart_contract_pay_from_pfbd_button',
  READ_TASK_SMART_CONTRACT_CANCEL_BUTTON = 'read_task_smart_contract_cancel_button',
  READ_TASK_SMART_CONTRACT_RENEWAL_BUTTON = 'read_task_smart_contract_renewal_button',
  READ_TASK_SMART_CONTRACT_AUTO_ALLOCATE_BUTTON = 'read_task_smart_contract_auto_allocate_button',
  READ_TASK_OPEN_SMART_CONTRACT_CANCEL_BUTTON = 'read_task_open_smart_contract_cancel_button',
  READ_TASK_CLOSED_SMART_CONTRACT_CANCEL_BUTTON = 'read_task_closed_smart_contract_cancel_button',
  READ_TASK_SMART_CONTRACT_TERMS_BLOCK = 'read_task_smart_contract_terms_block',
  READ_TASK_SMART_CONTRACT_RULES_BLOCK = 'read_task_smart_contract_rules_block',
  READ_TASK_SMART_CONTRACT_INVOICES_BLOCK = 'read_task_smart_contract_invoices_block',
  READ_TASK_SMART_CONTRACT_SMART_CONTRACT_TASK_BLOCK = 'read_task_smart_contract_smart_contract_task_block',
  READ_TASK_SMART_CONTRACT_ID = 'read_task_smart_contract_id',
  READ_TASK_SMART_CONTRACT_TERMS_PARTS_VIEW = 'read_task_smart_contract_terms_parts_view',
  READ_TASK_SMART_CONTRACT_TERMS_INTERNAl = 'read_task_smart_contract_terms_internal',
  READ_TASK_SMART_CONTRACT_TERMS_TRANSACTION_FEE = 'read_task_smart_contract_terms_transaction_fee',
  READ_TASK_SMART_CONTRACT_TERMS_PROVISION_FOR_BAD_DEBT = 'read_task_smart_contract_terms_provision_for_bad_debt',
  READ_TASK_SMART_CONTRACT_TERMS_PROVISION_FOR_REVISION = 'read_task_smart_contract_terms_provision_for_revision',
  CHANGE_TASK_SMART_CONTRACT_TERMS_INTERNAL = 'change_task_smart_contract_terms_internal',
  CHANGE_TASK_SMART_CONTRACT_TERMS_TRANSACTION_FEE = 'change_task_smart_contract_terms_transaction_fee',
  CHANGE_TASK_SMART_CONTRACT_TERMS_PROVISION_FOR_BAD_DEBT = 'change_task_smart_contract_terms_provision_for_bad_debt',
  CHANGE_TASK_SMART_CONTRACT_TERMS_PROVISION_FOR_REVISION = 'change_task_smart_contract_terms_provision_for_revision',
  READ_TASK_SMART_CONTRACT_TERMS_AV_SHARE_SCHEME = 'read_task_smart_contract_terms_av_share_scheme',
  CHANGE_TASK_SMART_CONTRACT_TERMS_AV_SHARE_SCHEME = 'change_task_smart_contract_terms_av_share_scheme',
  READ_TASK_SMART_CONTRACT_TERMS_AV_SHARE_SCHEME_FIXED = 'read_task_smart_contract_terms_av_share_scheme_fixed',
  READ_TASK_SMART_CONTRACT_TERMS_AV_SHARE_SCHEME_NET = 'read_task_smart_contract_terms_av_share_scheme_net',
  READ_TASK_SMART_CONTRACT_TERMS_AV_SHARE_SCHEME_RESIDUAL = 'read_task_smart_contract_terms_av_share_scheme_residual',
  READ_TASK_SMART_CONTRACT_SCOPE_OF_WORK_BLOCK = 'read_task_smart_contract_scope_of_work_block',
  READ_TASK_SMART_CONTRACT_COMPANY_SMART_CONTRACT_TEMPLATE_NAME = 'read_task_smart_contract_company_smart_contract_template_name',
  CHANGE_TASK_SMART_CONTRACT_COMPANY_SMART_CONTRACT_TEMPLATE_NAME = 'change_task_smart_contract_company_smart_contract_template_name',
  READ_TASK_SMART_CONTRACT_REVENUE_SHARING_BLOCK = 'read_task_smart_contract_revenue_sharing_block',
  READ_TASK_SMART_CONTRACT_REVENUE_SHARING_TASK_INVITED_MEMBERS = 'read_task_smart_contract_revenue_sharing_task_invited_members',
  READ_TASK_SMART_CONTRACT_REVENUE_SHARING_TASK_MEMBERS = 'read_task_smart_contract_revenue_sharing_task_members',
  READ_TASK_SMART_CONTRACT_REVENUE_SHARING_CANCEL_ALL_BUTTON = 'read_task_smart_contract_revenue_sharing_cancel_all_button',
  READ_TASKS_SMART_CONTRACT_SCOPE_OF_WORK_BATCH_BIND_BUTTON = 'read_tasks_smart_contract_scope_of_work_batch_bind_button',
  READ_TASKS_SMART_CONTRACT_SCOPE_OF_WORK_BATCH_UNBIND_BUTTON = 'read_tasks_smart_contract_scope_of_work_batch_unbind_button',
  READ_TASKS_SMART_CONTRACT_SCOPE_OF_WORK_BATCH_MOVE_TO_BUTTON = 'read_tasks_smart_contract_scope_of_work_batch_move_to_button',
  READ_TASKS_SMART_CONTRACT_SCOPE_OF_WORK_BATCH_MOVE_TO_SMART_CONTRACT_FIELD = 'read_tasks_smart_contract_scope_of_work_batch_move_to_smart_contract_field',
  READ_TASKS_SMART_CONTRACT_SCOPE_OF_WORK_BATCH_VOID_BUTTON = 'read_tasks_smart_contract_scope_of_work_batch_void_button',
  READ_TASKS_SMART_CONTRACT_SCOPE_OF_WORK_BATCH_PAY_FROM_DEPOSIT_BUTTON = 'read_tasks_smart_contract_scope_of_work_batch_pay_from_deposit_button',
  READ_TASKS_SMART_CONTRACT_SCOPE_OF_WORK_BATCH_PAY_INTERNAL_BUTTON = 'read_tasks_smart_contract_scope_of_work_batch_pay_internal_button',
  READ_TASK_SMART_CONTRACT_SCOPE_OF_WORK_TABLE_CHECKBOXES = 'read_task_smart_contract_scope_of_work_table_checkboxes',
  READ_TASK_SMART_CONTRACT_SCOPE_OF_WORK_TABLE_ITEM_ID_FIELD = 'read_task_smart_contract_scope_of_work_table_item_id_field',
  READ_TASK_SMART_CONTRACT_SCOPE_OF_WORK_TABLE_ITEM_NAME_FIELD = 'read_task_smart_contract_scope_of_work_table_item_name_field',
  READ_TASK_SMART_CONTRACT_SCOPE_OF_WORK_TABLE_PRICE_FIELD = 'read_task_smart_contract_scope_of_work_table_price_field',
  READ_TASK_SMART_CONTRACT_SCOPE_OF_WORK_TABLE_QUANTITY_FIELD = 'read_task_smart_contract_scope_of_work_table_quantity_field',
  READ_TASK_SMART_CONTRACT_SCOPE_OF_WORK_TABLE_ACTIVITY_FIELD = 'read_task_smart_contract_scope_of_work_table_activity_field',
  READ_TASK_SMART_CONTRACT_SCOPE_OF_WORK_TABLE_TOTAL_FIELD = 'read_task_smart_contract_scope_of_work_table_total_field',
  READ_TASK_SMART_CONTRACT_SCOPE_OF_WORK_TABLE_STATUS_FIELD = 'read_task_smart_contract_scope_of_work_table_status_field',
  READ_TASK_SMART_CONTRACT_SCOPE_OF_WORK_TABLE_INVOICE_FIELD = 'read_task_smart_contract_scope_of_work_table_invoice_field',
  READ_TASK_SMART_CONTRACT_SCOPE_OF_WORK_TABLE_REGULAR_INVOICE_CREATED_AT_FIELD = 'read_task_smart_contract_scope_of_work_table_regular_invoice_created_at_field',
  READ_TASK_SMART_CONTRACT_SCOPE_OF_WORK_TABLE_DESCRIPTION_FIELD = 'read_task_smart_contract_scope_of_work_table_description_field',
  READ_TASK_SMART_CONTRACT_SCOPE_OF_WORK_TABLE_DROPDOWN_FIELD = 'read_task_smart_contract_scope_of_work_table_dropdown_field',
  READ_TASK_SMART_CONTRACT_SCOPE_OF_WORK_TABLE_WITH_REGULAR_NET_INVOICES = 'read_task_smart_contract_scope_of_work_table_with_regular_net_invoices',
  READ_TASK_SMART_CONTRACT_SCOPE_OF_WORK_BIND_BUTTON = 'read_task_smart_contract_scope_of_work_bind_button',
  READ_TASK_SMART_CONTRACT_SCOPE_OF_WORK_UNBIND_BUTTON = 'read_task_smart_contract_scope_of_work_unbind_button',
  READ_TASK_SMART_CONTRACT_SCOPE_OF_WORK_MOVE_TO_BUTTON = 'read_task_smart_contract_scope_of_work_move_to_button',
  READ_TASK_SMART_CONTRACT_SCOPE_OF_WORK_MOVE_TO_TOGGLE_BIND_FIELD = 'read_task_smart_contract_scope_of_work_move_to_toggle_bind_field',
  READ_TASK_SMART_CONTRACT_SCOPE_OF_WORK_MOVE_TO_SMART_CONTRACT_FIELD = 'read_task_smart_contract_scope_of_work_move_to_smart_contract_field',
  READ_TASK_SMART_CONTRACT_SCOPE_OF_WORK_PAY_FROM_DEPOSIT_BUTTON = 'read_task_smart_contract_scope_of_work_pay_from_deposit_button',
  READ_TASK_SMART_CONTRACT_SCOPE_OF_WORK_PAY_INTERNAL_BUTTON = 'read_task_smart_contract_scope_of_work_pay_internal_button',
  READ_TASK_SMART_CONTRACT_SCOPE_OF_WORK_VOID_BUTTON = 'read_task_smart_contract_scope_of_work_void_button',
  CHANGE_TASK_SMART_CONTRACT_SCOPE_OF_WORK_TABLE_DESCRIPTION_FIELD = 'change_task_smart_contract_scope_of_work_table_description_field',
  READ_CREATE_TASK_ITEMS_MODAL_NEW_ITEM_TYPE_BUTTON = 'read_create_task_items_modal_new_item_type_button',
  READ_IMPORT_TASKS_TO_PROJECT_FORM_ITEMS = 'read_import_tasks_to_project_form_items',
  READ_IMPORT_TASKS_TO_PROJECT_FORM_MESSAGE_ATTRIBUTES = 'read_import_tasks_to_project_form_message_attributes',
  READ_IMPORT_TASKS_TO_PROJECT_FORM_FILE_ATTACHMENTS = 'read_import_tasks_to_project_form_file_attachments',
  READ_TASKS_BATCH_CHECK_IN_BUTTON = 'read_tasks_batch_check_in_button',
  READ_TASKS_ALL_RESULTS_PAGE = 'read_tasks_all_results_page',
  READ_TASK_RESULT_SEND_TO_WHITEBOARD_BUTTON = 'read_task_result_send_to_whiteboard_button',
  READ_TASK_RESULTS_SEND_TO_WHITEBOARD_BUTTON = 'read_task_results_send_to_whiteboard_button',
  READ_TASK_CREATE_TASK_ITEMS_FORM_CURRENCY = 'read_task_create_task_items_form_currency',
  READ_TASK_CREATE_TASK_ITEMS_FORM_SPLIT_PARTS = 'read_task_create_task_items_form_split_parts',
  READ_TASK_CREATE_TASK_ITEMS_FORM_GENERAL_LEDGER_ID = 'read_task_create_task_items_form_general_id',
  READ_TASKS_COMPACT_INDEX_PAGE = 'read_tasks_compact_index_page',
  READ_TASKS_COMPACT_HEADER_CREATE_PROJECT_IN_TEAM_LINK = 'read_tasks_compact_header_create_project_in_team_link',
  READ_USERS_GROUP_TASKS_PAGE = 'read_users_group_tasks_page',
  READ_TASKS_COMPACT_INDEX_PAGE_MENU_PROJECTS_FILTER = 'read_tasks_compact_index_page_menu_projects_filter',
  READ_TASKS_COMPACT_INDEX_PAGE_MENU_USER_GROUPS_FILTER = 'read_tasks_compact_index_page_menu_user_groups_filter',
  READ_TASKS_COMPACT_TIMELINE_PAGE = 'read_tasks_compact_timeline_page',
  READ_TASKS_CHECKED_PRICE_AMOUNT = 'read_tasks_checked_price_amount',
  READ_TASKS_CREATE_SEARCH_FILTER_MODAL_BUTTON = 'read_tasks_create_search_filter_modal_button',
  READ_TASKS_COMPACT_TABLE_SETTINGS = 'read_tasks_compact_table_settings',
  READ_TASKS_COMPACT_TABLE_SETTINGS_HIDDEN_TASKS_FIELD = 'read_tasks_compact_table_settings_hidden_tasks_field',
  READ_TASKS_COMPACT_TABLE_SETTINGS_CANCELED_TASKS_FIELD = 'read_tasks_compact_table_settings_canceled_tasks_field',
  READ_TASKS_COMPACT_TABLE_SETTINGS_DONE_TASKS_FIELD = 'read_tasks_compact_table_settings_done_tasks_field',
  READ_TASK_MESSAGES_ASSOCIATIONS = 'read_task_messages_associations'
}

export const ignoredTasksFilters = ['name'];

export const ignoredCompactTasksFilters = [
  FetchTasksFilterFields.STATUS,
  FetchTasksFilterFields.PROJECT_NANO_ID
];

export const usersGroupCheckedTasksStateCacheKey =
  'users-group-checked-tasks-state';

export const workloadStatusesFilterValue = {
  in: [TaskStatuses.IN_PROGRESS, TaskStatuses.QUALITY_CONTROL]
};
