export default {
  addEvent: 'Add event',
  addPerformer: 'Add performer',
  addUser: 'Add user',
  addTerms: 'Add terms...',
  addYourTeamMembersToCompany: 'Add your team members to company',
  accountReceivables: 'Account receivables',
  agreement: 'Agreement',
  artistsTeam: 'Artists team',
  airTable: 'Airtable',
  automaticDebtRepayment: 'Automatic debt repayment',
  automaticPaymentSC: 'Automatic payment SC',
  automaticContractorPayment: 'Automatic contractor payment',
  autoCheckIn: 'Auto check-in',
  autoCheckInRule: 'Auto check-in rule',
  autoCheckInRules: 'Auto check-in rules',
  author: 'Author',
  basicInfo: 'Basic info',
  blockAccount: 'Block account',
  changeEmail: 'Change email',
  changeCompanyOwner: 'Change company owner',
  createCompany: 'Create company',
  created: 'Created',
  createAndViewCompany: 'Create and view company',
  debtLimit: 'Debt limit',
  deferredPayment: 'Deferred payment (days)',
  defaultTaskState: 'Default task state',
  description: 'Description',
  deliveryTime: 'Delivery time (days)',
  deleteTemplate: 'Delete template',
  downloadAllItems: 'Download All Items',
  enterTheEmailAddressOfTheUser:
    'Enter the email address of the user you want to share your tasks and projects with',
  eventDispatcher: 'Event dispatcher',
  eventName: 'Event name',
  favorite: 'Favorite companies',
  financeInfo: 'Finance info',
  files: 'Files',
  followers: 'Followers',
  finLastPayments: 'Fin last payment',
  finStatus: 'Fin status',
  select: 'Select teams',
  payoutStatus: 'Payout status',
  performers: 'Performers',
  performersTat: 'Performers TAT',
  performersTeam: 'Performers team',
  plural: 'Teams',
  pluralDowncase: 'teams',
  singular: 'Team',
  add: 'Add team',
  'add...': 'Add teams...',
  change: 'Change teams',
  change_creator: 'Change team creator',
  create: 'Create team',
  creator: 'Team creator',
  create_and_view: 'Create and view team',
  dashboard: 'Dashboard',
  select_with_dots: 'Select teams...',
  selected: 'Selected teams',
  no: 'No teams',
  of: 'Team of',
  type: 'Team type',
  select_type: 'Select team type',
  view: 'View team',
  members: 'Team members',
  selectUser: 'Select user',
  users: 'Team users',
  profile: 'Profile',
  provideAccess: 'Provide access',
  provisionForBadDebt: 'Provision for bad debt (%)',
  provisionForRevision: 'Provision for revision (%)',
  permissions: 'Permissions',
  personalization: 'Personalization',
  recalculateBalances: 'Recalculate balances',
  records: 'Records',
  reports: 'Reports',
  requirements: 'Requirements',
  resume: 'Resume',
  restoreEvent: 'Restore event',
  marketingData: 'Marketing data',
  hourlyRate: 'Hourly rate ($/h)',
  holdRate: 'Hold rate',
  bookId: 'Book id',
  info: 'Info',
  inviteNewUser: 'Invite new user',
  delete: 'Delete Team',
  deleted: 'Team Deleted',
  allCompanies: 'All companies',
  allPerformers: 'All performers',
  companies: 'Companies',
  corevsharers: 'Corevsharers',
  clients: 'Clients',
  name: 'Name',
  ownerId: 'Owner id',
  companyMembers: 'Company members',
  companyName: 'Company name',
  companyHasNoOwner: 'Company has no owner',
  companyLibrary: 'Company library',
  companyUsers: 'Company users',
  editCompanyPicture: 'Edit company picture',
  goToProfile: 'Go to profile',
  handler: 'Handler',
  lastPayments: 'Last payment',
  lastActivity: 'Last activity',
  list: 'List',
  leaders: 'Leaders',
  cm: 'CM',
  pm: 'PM',
  recentCompanies: 'Recent companies',
  respondTime: 'Respond time',
  revenueShare: 'Revenue share',
  revisions: 'Revisions',
  selectCompany: 'Select company',
  selectCompanies: 'Select companies...',
  selectCompanyWithoutEllipsis: 'Select company',
  selectEvent: 'Select event',
  status: 'Status',
  searchCompanies: 'Search companies',
  searchPerformers: 'Search performers',
  settings: 'Settings',
  sendInvitations: 'Send invitations',
  shares: 'Shares',
  smartContractSettings: 'Smart contract settings',
  finance: {
    balance: 'Balance',
    debt: 'Debt',
    revenue: 'Revenue',
    sigmaPayments: 'Σ Payments'
  },
  statuses: {
    new: 'New',
    active: 'Active',
    paused: 'Paused',
    declined: 'Declined',
    realDecline: 'Real decline',
    noPayments: 'No payments'
  },
  source: 'Source',
  subbooks: 'Subbooks',
  suspendEvent: 'Suspend event',
  scTemplates: 'SC templates',
  tasksWithoutSourceFiles: 'Tasks without source files',
  tat: 'TAT',
  terms: 'Terms',
  transactionFee: 'Transaction fee (%)',
  trigger: 'Trigger',
  viewPerTask: 'Views per task',
  vendorForm: 'Vendor form',
  billingInfo: 'Billing info',
  items: 'Items',
  wire: 'WIRE',
  card: 'Card',
  preferredPaymentMethod: 'Preferred payment method',
  addBillingInfoToSetPaymentMethod:
    'Add billing info below to set it as default payment method',
  pleaseSelectBillingInfo: 'Please select a billing info',
  documents: 'Documents',
  merge: 'Merge',
  mergeCompany: 'Merge company',
  mergeCompaniesWarning: {
    caution: 'Caution!',
    allUserInformationFromTheSelected: 'All user information from the selected',
    company: 'company',
    willBe: 'will be',
    merged: 'merged'
  },
  myCompanies: 'My companies',
  uploadInvoices: 'Upload Invoices',
  usersInvited: 'Users invited'
};
