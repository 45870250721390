import { ItemsKeyType } from '../../keys';

const itemsKeys: ItemsKeyType = {
  activity: 'Activity',
  add: 'Add item',
  allProformaInvoices: 'All proforma invoices',
  allProformaItemCategories: 'All proforma item categories',
  allProformaProjects: 'All proforma projects',
  allProformaTasks: 'All proforma tasks',
  areYouSureYouWantToDeleteThisItemYouWillNotBeAbleToUndoThisAction:
    'Are you sure you want to delete this item? You will not be able to undo this action.',
  bind: 'Bind',
  bindingStatus: 'Binding status',
  bindingStatuses: {
    postponed: 'Postponed',
    mixed: 'Mixed',
    binded: 'Binded'
  },
  bound: 'Bound',
  createDate: 'Create date',
  createNewTag: 'Create new tag',
  createItemTag: 'Create item tag',
  defaultSplitParts: 'Default split parts',
  deleteItemTag: 'Delete item tag',
  description: 'Description',
  eachItemWillHaveASetNumberOfRegularInvoicesPricedProportionally:
    'Each item will have a set number of regular invoices, priced proportionally.',
  groupSimilarItems: 'Group similar items',
  hideCompleted: 'Hide completed items',
  id: 'ID',
  inDraft: 'In draft',
  invoice: 'Invoice',
  itemTag: 'Item tag',
  itemTags: 'Item tags',
  mixed: 'Mixed',
  move: 'Move item',
  moveTo: 'Move to',
  name: 'Item name',
  noScopeOfWorkYet: 'No scope of work yet',
  noSplit: 'No split',
  payFromDeposit: 'Pay from deposit',
  plural: 'Items',
  pluralLowerCase: 'items',
  postpone: 'Postpone',
  postponed: 'Postponed',
  postponedItems: 'Postponed items',
  postponedItemsTotal: 'Postponed items total',
  price: 'Price',
  projectCreated: 'Project created',
  projectCreatedDate: 'Project created date',
  qty: 'Qty',
  rendering: 'Rendering',
  scopeOfWork: 'Scope of work',
  searchByItemName: 'Search by item name',
  select: 'Select item...',
  selectedTotal: 'Selected items total',
  sentDate: 'Sent date',
  shownTotal: 'Shown items total',
  singular: 'Item',
  singularLowerCase: 'item',
  splitParts: 'Split parts',
  status: 'Status',
  statusChangeDate: 'Status change date',
  subTotal: 'Subtotal',
  tag: 'Tag',
  taskCreated: 'Task created',
  taskCreatedDate: 'Task created date',
  threeDRenderingServicesPrepayment: '3D rendering services prepayment',
  total: 'Total',
  typeNotesHere: 'Type notes here...',
  unbind: 'Unbind',
  unbound: 'Unbound',
  updateItemTag: 'Update item tag',
  void: 'Void',
  youShouldAddAtLeastOneItem: 'You should add at least one item..'
};

export default itemsKeys;
