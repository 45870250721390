import { GraphQLClient } from 'graphql-request';

import {
  CACHE_GRAPHQL_API_URI,
  GRAPHQL_API_URI,
  GRAPHQL_READ_API_URI,
  GRAPHQL_READ_FIN_API_URI,
  TEST_GRAPHQL_API_URI,
  UPLOADS_API_URI
} from '../../../../../config';
import { graphQLReadFinPath } from '../../../../../api/common/backendGraphQLClients';

class CustomGraphQLClient extends GraphQLClient {
  private endpoint: string;

  constructor(endpoint: string, options?: RequestInit) {
    super(endpoint, options);
    this.endpoint = endpoint;
  }

  addQueryParams(queryName: string, requestId: string) {
    this.setEndpoint(
      `${this.endpoint}?queryName=${queryName}&requestId=${requestId}`
    );
  }
}

export const graphQLTestClient = new CustomGraphQLClient(TEST_GRAPHQL_API_URI, {
  credentials: 'include',
  mode: 'cors'
});

export const graphQLClient = new CustomGraphQLClient(GRAPHQL_API_URI, {
  credentials: 'include',
  mode: 'cors'
});

export const graphQLReadClient = new CustomGraphQLClient(GRAPHQL_READ_API_URI, {
  credentials: 'include',
  mode: 'cors'
});

export const graphQLCacheClient = new CustomGraphQLClient(
  CACHE_GRAPHQL_API_URI,
  {
    credentials: 'include',
    mode: 'cors'
  }
);

export const graphQLReadFinClient = new CustomGraphQLClient(
  GRAPHQL_READ_FIN_API_URI,
  {
    credentials: 'include',
    mode: 'cors'
  }
);

export const graphQLReadFinBackendClient = new CustomGraphQLClient(
  graphQLReadFinPath,
  {
    credentials: 'include',
    mode: 'cors'
  }
);

export const uploadsClient = new CustomGraphQLClient(
  UPLOADS_API_URI || GRAPHQL_API_URI,
  {
    credentials: 'include',
    mode: 'cors'
  }
);
